<style lang="scss">
.local-player {
  width: 100%;
  height: 170px;
  margin-top: 0px;
}

.external-player {
  width: 100%;
  height: 100%;
  margin-top: 0px;
}

.username-chip {
  position: absolute !important;
  top: 135px;
  left: 10px;
  z-index: 9;
  background-color: rgba(0, 0, 0, 0.5) !important;
}
</style>

<template>
  <v-app>
    <v-app-bar dark app clipped-right clipped-left>
      <v-app-bar-title v-if="call">
        {{ call.lead.identifier_code }} - {{ call.participant.name }}
      </v-app-bar-title>
      <v-spacer></v-spacer>
      <ParticipantsMenu
        @click:participant="changeParticipant"
        :participants="participants"
      />
      <v-btn icon>
        <v-icon>mdi-share-variant-outline</v-icon>
      </v-btn>
      <v-divider vertical class="mx-2"></v-divider>
      <NetworkStatusButton
        ref="networkStatusButton"
        offset-y
        bottom
        left
        dark
      />
      <v-menu>
        <template v-slot:activator="{ on, attrs }">
          <v-btn v-bind="attrs" v-on="on" icon>
            <v-icon>mdi-cog</v-icon>
          </v-btn>
        </template>

        <v-list dark>
          <v-subheader>OPÇÕES</v-subheader>
          <CameraPicker
            :localVideoTrack="localVideoTrack"
            @change="changeCamera"
          />
          <MicrophonePicker
            :localAudioTrack="localAudioTrack"
            @change="changeMicrophone"
          />
        </v-list>
      </v-menu>
      <v-btn @click="toggleLocalCamera()" icon>
        <v-icon v-if="!localVideoMuted">mdi-camera</v-icon>
        <v-icon v-else>mdi-camera-off</v-icon>
      </v-btn>
      <v-btn @click="toggleMicMute()" icon>
        <v-icon v-if="!localMicMuted">mdi-microphone</v-icon>
        <v-icon v-else>mdi-microphone-off</v-icon>
      </v-btn>
      <v-btn @click="confirmDialog = true" color="red" dark>
        <v-icon left>mdi-phone-hangup</v-icon> Encerrar
      </v-btn>
    </v-app-bar>

    <v-navigation-drawer dark permanent right app clipped width="250">
      <div class="d-flex">
        <div
          v-show="!localVideoMuted"
          id="local-player"
          class="local-player d-absolute"
          @click="toggleLocalVideoFit()"
        >
          <v-chip class="username-chip" small label dark>
            {{ user.name }}
            <v-icon
              v-if="localMicMuted"
              size="14"
              class="ml-2"
              color="red lighten-1"
              >mdi-microphone-off</v-icon
            >
          </v-chip>
        </div>
      </div>

      <div
        v-if="localVideoMuted"
        class="local-player d-flex justify-center align-middle"
        style="background-color: #1e1e1e"
      >
        <!-- <v-icon color="grey" large>mdi-camera-off</v-icon> -->
      </div>
    </v-navigation-drawer>

    <v-main v-if="participants.length > 0">
      <div
        @click="toggleParticipantVideoFit()"
        id="external-player"
        class="external-player"
      ></div>
    </v-main>
    <v-main v-else style="background-color: #333">
      <v-container
        class="d-flex justify-center align-middle"
        style="height: 100%"
        v-if="call"
      >
        <div class="mx-auto my-auto">
          <v-card class="text-center" dark>
            <v-card-text>
              <v-icon size="30">mdi-account-group-outline</v-icon><br />
              O convidado não está na chamada
            </v-card-text>
          </v-card>
        </div>
      </v-container>
    </v-main>
    <v-navigation-drawer
      dark
      permanent
      left
      app
      clipped
      width="290"
      style="overflow-y: none"
      v-if="call"
    >
      <v-list two-lines dense>
        <v-subheader>FOTOGRAFIAS</v-subheader>
        <v-list-item v-for="picture in call.pictures" :key="picture.id">
          <v-list-item-avatar tile>
            <v-img :src="picture.src"></v-img>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title>
              {{ picture.name }}
            </v-list-item-title>
            <v-list-item-subtitle>
              {{ picture.description }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list>

      <template v-slot:append>
        <VideoCallPictureViewer
          ref="viewer"
          class="ma-1"
          @discarted="pictureUrl = null"
          @confirmed="handleSavePicture"
        />
        <div class="ma-2">
          <v-btn
            :disabled="!participant"
            block
            color="info"
            @click="handleTakePicture()"
            elevation="0"
          >
            <v-icon left>mdi-camera</v-icon> Fotografar
          </v-btn>
        </div>
      </template>
    </v-navigation-drawer>

    <v-dialog v-model="confirmDialog" width="400" persistent>
      <v-card dark>
        <v-card-title> Encerrar vídeo chamada? </v-card-title>
        <v-card-text>
          O participante será desconectado e o link será cancelado. Para
          realizar um novo contacto será necessário enviar um novo link.
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="confirmDialog = false"> Cancelar </v-btn>
          <v-btn @click="handleLeave()" color="red">
            <v-icon left>mdi-phone-hangup</v-icon> Encerrar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-app>
</template>

<script>
import {
  showForPerit as show,
  joinPerit,
  finish,
} from "@/services/video_calls/video_calls";
import { store as storePicture } from "@/services/video_calls/video_call_pictures";
import AgoraRTC from "agora-rtc-sdk-ng";
const AGORA_APP_ID =
  /*process.env.AGORA_API_ID*/ "82ead256d1474b709ffa59d16284afc7";
import ParticipantsMenu from "@/components/video_calls/ParticipantsMenu.vue";
import NetworkStatusButton from "@/components/video_calls/NetworkStatusButton";
import MicrophonePicker from "@/components/video_calls/MicrophonePicker.vue";
import CameraPicker from "@/components/video_calls/CameraPicker.vue";
import VideoCallPictureViewer from "@/components/video_calls/VideoCallPictureViewer";

import {
  getImageFromVideoElement,
  getUriFromVideoElement,
  blobToFile,
  dataURItoBlob,
} from "@/tools/image";

export default {
  components: {
    ParticipantsMenu,
    NetworkStatusButton,
    MicrophonePicker,
    CameraPicker,
    VideoCallPictureViewer,
  },
  data() {
    return {
      call: null,
      loading_call: false,
      client: null,
      localPlayer: null,
      mainPlayer: null,

      localAudioTrack: null,
      localVideoTrack: null,

      localVideoFit: "cover",
      participantVideoFit: "contain",

      localVideoMuted: false,
      localMicMuted: false,

      participants: [],
      participant: null,

      downlinkNetworkQuality: 0,
      uplinkNetworkQuality: 0,

      pictureUrl: null,

      confirmDialog: false,
    };
  },

  methods: {
    handlePreviewPicture() {
      this.$refs.viewer.show(this.pictureUrl);
    },

    handleDiscardPicture() {
      this.pictureUrl = null;
      this.$refs.viewer.hide();
    },

    handleTakePicture() {
      this.pictureUrl = getUriFromVideoElement(
        document.querySelector("#external-player>div>video")
      );

      this.handlePreviewPicture();
    },

    async handleSavePicture(payload) {
      const { name, description, url } = payload;

      const blob = dataURItoBlob(url);
      const file = blobToFile(blob, name);

      let data = new FormData();
      data.append("picture", file);
      data.append("name", name);
      data.append("description", description);
      data.append("video_call_id", this.call.id);

      const response = await storePicture(data);
      this.call.pictures.push(response.data);
    },

    async handleLeave() {
      await finish(this.$route.params.key);
      await this.client.leave();
      await this.localVideoTrack.close();
      await this.localAudioTrack.close();
      this.$router.push({ name: "video_call_ended" });
    },

    async loadCall() {
      this.loading_call = true;
      try {
        const response = await show(this.$route.params.key);
        this.call = response.data;
      } finally {
        this.loading_call = false;
      }
    },

    handleChangeNetworkQuality(stats) {
      this.uplinkNetworkQuality = stats.uplinkNetworkQuality;
      this.downlinkNetworkQuality = stats.downlinkNetworkQuality;
    },

    changeCamera(device) {
      this.localVideoTrack.setDevice(device.deviceId);
    },
    changeMicrophone(device) {
      this.localAudioTrack.setDevice(device.deviceId);
    },

    async turnOnLocalTrack() {
      this.localAudioTrack = await AgoraRTC.createMicrophoneAudioTrack();
      this.localVideoTrack = await AgoraRTC.createCameraVideoTrack({
        optimizationMode: "motion",
      });
      this.localVideoTrack.play(this.getLocalPlayer(), {
        fit: this.localVideoFit,
      });
      this.localVideoMuted = this.localVideoTrack.muted;
      this.localMicMuted = this.localAudioTrack.muted;
    },

    async toggleLocalCamera() {
      if (this.localVideoTrack.muted) {
        this.localVideoTrack.setMuted(false);
        this.localVideoMuted = false;
        return;
      }

      this.localVideoTrack.setMuted(true);
      this.localVideoMuted = true;
      return;
    },

    toggleLocalVideoFit() {
      this.localVideoFit =
        this.localVideoFit === "contain" ? "cover" : "contain";
    },

    toggleParticipantVideoFit() {
      this.participantVideoFit =
        this.participantVideoFit === "contain" ? "cover" : "contain";
    },

    async toggleMicMute() {
      if (this.localAudioTrack.muted) {
        this.localAudioTrack.setMuted(false);
        this.localMicMuted = false;
        return;
      }

      this.localAudioTrack.setMuted(true);
      this.localMicMuted = true;
      return;
    },

    getExternalPlayer() {
      return document.querySelector("#external-player");
    },

    getLocalPlayer() {
      //   return document.querySelector("#external-player");
      return document.querySelector("#local-player");
    },

    async joinChannel() {
      this.joiningChannel = true;
      try {
        const { token } = await joinPerit(this.$route.params.key);

        this.client = AgoraRTC.createClient({ mode: "rtc", codec: "h264" });
        await this.client.join(
          AGORA_APP_ID,
          this.$route.params.key,
          token,
          this.user.id
        );

        this.participants = this.client.remoteUsers;

        this.client.on("user-published", (user, mediaType) =>
          this.handleUserPublished(user, mediaType)
        );

        this.client.on("user-unpublished", (user, mediaType) =>
          this.handleUserUnpublished(user, mediaType)
        );

        this.client.on("user-left", (user) => this.handleUserLeft(user));

        this.$refs.networkStatusButton.init(this.client);

        this.client.publish(this.localVideoTrack);
        this.client.publish(this.localAudioTrack);
      } finally {
        this.joiningChannel = false;
      }
    },

    async handleUserPublished(user, mediaType) {
      await this.client.subscribe(user, mediaType);

      if (mediaType === "video") {
        if (this.participant) {
          this.participant.videoTrack.stop();
        }

        user.videoTrack.play(this.getExternalPlayer(), {
          fit: this.participantVideoFit,
        });
      }
      if (mediaType === "audio") {
        if (this.participant) {
          this.participant.audioTrack.stop();
        }

        user.audioTrack.play();
      }

      this.participant = user;
    },

    async handleUserUnpublished(user, mediaType) {
      if (mediaType === "video") {
        if (this.participant && this.participant.videoTrack) {
          this.participant.videoTrack.stop();
        }
      }
      if (mediaType === "audio") {
        if (this.participant && this.participant.audioTrack) {
          this.participant.audioTrack.stop();
        }
      }
    },

    async handleUserLeft() {
      this.participant = null;
    },

    async changeParticipant(participant) {
      if (this.participant) {
        await this.client.unsubscribe(this.participant);
      }

      this.participant = participant;

      if (participant.hasVideo) {
        await this.client.subscribe(participant, "video");
        participant.videoTrack.play(this.getExternalPlayer(), {
          fit: this.participantVideoFit,
        });
      }

      if (participant.hasAudio) {
        await this.client.subscribe(participant, "audio");
        participant.audioTrack.play(this.getExternalPlayer());
      }
    },

    async takeScreenshot() {
      const uri = getUriFromVideoElement(
        document.querySelector("#external-player>div>video")
      );
      this.previewImg = uri;
      this.previewImgName =
        this.previewImgName === null
          ? "Fotografia " + Math.floor(new Date())
          : this.previewImgName;

      // const file = blobToFile(blob, fileName);
      let data = new FormData();
      data.append("attachment", file);
      data.append("name");
      data.append("description", "");
    },
  },

  async mounted() {
    await this.turnOnLocalTrack();
    await this.joinChannel();
  },

  watch: {
    async localVideoFit(value) {
      await this.localVideoTrack.stop();

      this.localVideoTrack.play(this.getLocalPlayer(), {
        fit: value,
      });
    },
    async participantVideoFit(value) {
      await this.participant.videoTrack.stop();

      this.participant.videoTrack.play(this.getExternalPlayer(), {
        fit: value,
      });
    },
  },

  computed: {
    user() {
      return this.$store.getters["auth/getUser"];
    },
  },

  created() {
    this.loadCall();

    this.client = AgoraRTC.createClient({ mode: "rtc", codec: "h264" });
  },

  async beforeDestroy() {
    if (this.participant.hasVideo) {
      await this.participant.videoTrack.close();
    }

    if (this.participant.hasAudio) {
      await this.participant.audioTrack.close();
    }

    await this.client.unsubscribe(this.participant);
  },
};
</script>
