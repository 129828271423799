<template>
  <div>
    <v-card v-if="url">
      <v-form @submit.prevent="handleConfirm()">
        <v-img :src="url" style="min-width: 100% !important"></v-img>
        <v-card-text>
          <v-text-field
            v-model="name"
            dense
            filled
            label="Nome"
            clearable
            autofocus
          ></v-text-field>
          <v-text-field
            v-model="description"
            dense
            filled
            label="Descrição"
            clearable
          ></v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-btn @click="handleDiscard()" color="red" text> Descartar </v-btn>
          <v-spacer></v-spacer>
          <v-btn type="submit" color="green" text> Confirmar </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </div>
</template>

<script>
export default {
  data() {
    return {
      url: "",
      name: "",
      description: "",
    };
  },

  methods: {
    handleDiscard() {
      this.url = "";
      this.$emit("discarted");
    },

    handleConfirm() {
      this.$emit("confirmed", {
        url: this.url,
        name: this.name,
        description: this.description,
      });
      this.url = "";
      this.name = "";
      this.description = "";
    },

    show(url) {
      this.url = url;
      this.name = this.name
        ? this.name
        : "Fotografia " + Math.floor(new Date());
      this.description = "";
    },
    hide() {
      this.url = null;
    },
  },
};
</script>
