import Vue from "vue";

export async function store(payload) {
  const response = await Vue.prototype.$http.post(
    `/video_calls/pictures`,
    payload
  );

  return response.data;
}
