<template>
  <v-menu offset-y bottom left>
    <template v-slot:activator="{ on, attrs }">
      <v-btn v-on="on" v-bind="attrs" icon>
        <v-icon> mdi-account-group-outline </v-icon>
      </v-btn>
    </template>
    <v-list width="250" dense dark>
      <v-subheader>PARTICIPANTES</v-subheader>
      <v-list-item
        v-for="participant in participants"
        :key="participant.uid"
        link
        @click="$emit('click:participant', participant)"
      >
        <v-list-item-icon>
          <v-icon>mdi-account-circle</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>
            {{ participant.uid }}
          </v-list-item-title>
        </v-list-item-content>
        <v-list-item-action>
          <div>
            <v-icon v-if="!participant.hasAudio" color="red" class="ml-1" small>
              mdi-microphone-off
            </v-icon>
            <v-icon v-if="!participant.hasVideo" color="red" class="ml-1" small>
              mdi-camera-off
            </v-icon>
          </div>
        </v-list-item-action>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
export default {
  props: {
    participants: Array,
  },
};
</script>
